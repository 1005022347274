// https://every-layout.dev/layouts/stack/

@mixin stack($margin: var(--unit), $recursive: false, $split: null) {

	@if $recursive {

		& * {
			margin-block: 0;
		} 

		& * + * {
			margin-block-start: $margin;
		}

	} @else {

		& > * {
			margin-block: 0;
		} 

		& > * + * {
			margin-block-start: $margin;
		}

	}

	@if $split {

		display: flex;
		flex-direction: column;
		justify-content: flex-start;

		&:only-child {
			block-size: 100%;
		}

		& > :nth-child(#{$split}) {
			margin-block-end: auto;
		}

	}

}

@mixin unstack() {

	& + * {
		margin-block-start: 0;
	}

}