.navigation {
	// background: var(--block-background-color;
	border-block: 1px solid var(--border-color);

	ul {
		display: flex;
		// gap: unit(0.5) unit(); // https://caniuse.com/#feat=flexbox-gap
		flex-wrap: wrap;
		justify-content: center;
		line-height: 1;
		list-style: none;
		// padding: unit(0.5) 0;
		padding: unit(0.25) 0;
	}

	li {
		margin: unit(0.25) unit(0.5);
	}

	a {
		font-weight: bold;
		letter-spacing: 0.125rem;
		text-decoration: none;
		text-transform: uppercase;

		&.active {
			color: var(--color);
			text-decoration: underline;
			text-decoration-thickness: 0.125em;
			text-underline-offset: 0.25em;
		}

	}

}