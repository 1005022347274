@mixin table-block($key-column-width: null, $hidden-rows: null, $row-padding: null) {

	display: block; // make table elements not act like table elements

	thead,
	tbody,
	tfoot,
	tr,
	th,
	td,
	caption {
		display: block;
	}

	th, td {
		min-block-size: var(--unit);
		padding: 0;
	}

	@if $hidden-rows {

		@each $row in $hidden-rows {
			 thead tr:nth-child(#{$row}) {
				@include visually-hidden();
			}
		}

	}

	@if $row-padding {

		tr {
			padding: $row-padding;
		}

	}

	@if $key-column-width {

		td[data-key] {
			padding-inline-start: $key-column-width; // magic number, this is the weakness of this method
			position: relative;

			&::before {
				content: attr(data-key);
				inline-size: $key-column-width;
				inset-inline-start: 0;
				position: absolute;
			}

		}

	}

}