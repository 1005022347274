@mixin link-colors($link, $visited: $link, $hover: $link, $focus: $hover, $active: $hover) {

	&:link {
		color: $link;
	}

	&:visited {
		color: $visited;
	}

	&:hover {
		color: $hover;
	}

	&:focus {
		color: $focus;
	}

	&:active {
		color: $active;
	}

}