.release-container {
	display: grid;
	gap: unit();
	grid-template-columns: repeat(auto-fill, minmax(15em, 1fr));
}

.release {
	background-color: var(--block-background-color);
}

// .release__cover {}

.release__info {
	padding: unit();
}

.release__title {
	@include vr(1.125);
}

.release__date {
	color: var(--color-muted);
	display: block;
}

.release__links {
	list-style: none;
	margin-block-start: unit();

	a {
		@include link-colors(var(--color));
	}

	[class^="icon"] {
		@include icon($size: unit(0.625), $vertical-align: middle);
		margin-inline-end: unit(0.5);
	}

}