a {
	transition: 0.2s;

	&:link {
		color: var(--link-color);
	}

	&:visited {
		color: var(--link-color-visited)
	}

	&:hover {
		color: var(--link-color-hover);
	}

	&:focus {
		color: var(--link-color-focus);
	}

	&:active {
		color: var(--link-color-active);
		transition: unset;
	}

}