.show-table {
	background-color: var(--block-background-color);
	table-layout: fixed;

	tbody tr {
		border-block-start: 1px solid var(--border-color);
	}

	td, th {
		padding: unit(0.5) unit(0.75);
	}

	@media (max-width: $breakpoint-medium) {
		@include table-block($key-column-width: unit(2.75) /* magic number */, $row-padding: unit(0.5) unit(0.75));

		::before {
			color: var(--color-muted);
			font-variant: small-caps;
			letter-spacing: 0.0625em;
			text-transform: lowercase;
		}

	}

}

// .show-table__temporal-heading-row {}

.show-table__temporal-heading {
	letter-spacing: 0.0625em;
	text-transform: uppercase;
}

.show-table__key-heading-row {
	@include visually-hidden();
}

.show-table__key-heading {
	color: var(--color-muted);
	font-variant: small-caps;
	font-weight: normal;
	letter-spacing: 0.0625em;
	text-transform: lowercase;
};

// .show-table__date {}

// .show-table__venue {}

.show-table__city {
	white-space: nowrap;
}

// .show-table__with {}

.show-table__performer-list {
	list-style: none;

	li {
		@include truncate-text();
	}

}

.show-table__no-shows {
	text-align: center;
}