.article {
	display: grid;
	gap: unit();
	grid-template-areas:
		"header header header"
		". main ."
		"footer footer footer"
	;
	grid-template-rows: min-content 1fr min-content;
	grid-template-columns: minmax(0, 1fr) minmax(0, $breakpoint-medium) minmax(0, 1fr);

	&__header {
		grid-area: header;

		display: grid;
		gap: 0 unit();
		grid-template-areas:
			". logo ."
			". nav ."
		;
		grid-template-columns: minmax(0, 1fr) minmax(0, $breakpoint-medium) minmax(0, 1fr);

		.logo-container {
			grid-area: logo;
			padding: unit() 0;
		}

		.navigation {
			grid-area: nav;
		}

	}

	&__main {
		grid-area: main;
	}

	&__article {
		@include stack();

		img {
			border: 1px solid var(--border-color);
		}

	}

	&__title {
		@include unstack();
		@include visually-hidden();
	}

	&__footer {
		border-block-start: 1px solid var(--border-color);
		grid-area: footer;
		padding: unit();
	}

}