:root {
  --font-family:	-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
  --line-height:	1.5;
  --measure:	60ch;
  --unit:	calc(var(--line-height) * 1rem);
}

abbr {
  text-decoration: inherit;
}

button {
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

html {
  block-size: 100%;
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

img {
  max-inline-size: 100%;
  vertical-align: middle;
}

:root {
  line-height: var(--line-height);
}

table {
  border-collapse: collapse;
  inline-size: 100%;
}

th {
  text-align: start;
  vertical-align: top;
}

td {
  vertical-align: top;
}

caption {
  caption-side: bottom;
  text-align: start;
}

:root {
  --color-black:	hsl( 0, 0%, 0%);
  --color-gray-dark:	hsl( 0, 0%, 20%);
  --color-gray-medium:	hsl( 0, 0%, 50%);
  --color-gray-light:	hsl( 0, 0%, 90%);
  --color-white:	hsl( 0, 0%, 100%);
  --color-red:	rgb(218, 83, 92);
  --background-color:	var(--color-gray-dark);
  --color:	var(--color-gray-light);
  --accent-color:	var(--color-red);
  --border-color:	var(--color-gray-dark);
  --color-muted:	var(--color-gray-medium);
  --block-background-color:	var(--color-black);
  --link-color:	var(--color-red);
  --link-color-visited:	var(--link-color);
  --link-color-hover:	var(--link-color);
  --link-color-focus:	var(--link-color-hover);
  --link-color-active:	var(--color);
  --line-height: 1.25;
}

.article {
  display: grid;
  gap: 1.5rem;
  grid-template-areas: "header header header" ". main ." "footer footer footer";
  grid-template-rows: min-content 1fr min-content;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1024px) minmax(0, 1fr);
}
.article__header {
  grid-area: header;
  display: grid;
  gap: 0 1.5rem;
  grid-template-areas: ". logo ." ". nav .";
  grid-template-columns: minmax(0, 1fr) minmax(0, 1024px) minmax(0, 1fr);
}
.article__header .logo-container {
  grid-area: logo;
  padding: 1.5rem 0;
}
.article__header .navigation {
  grid-area: nav;
}
.article__main {
  grid-area: main;
}
.article__article > * {
  margin-block: 0;
}
.article__article > * + * {
  margin-block-start: var(--unit);
}
.article__article img {
  border: 1px solid var(--border-color);
}
.article__title {
  block-size: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  inline-size: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
}
.article__title + * {
  margin-block-start: 0;
}
.article__footer {
  border-block-start: 1px solid var(--border-color);
  grid-area: footer;
  padding: 1.5rem;
}

.base {
  background-attachment: fixed;
  background-color: var(--background-color);
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), black), url("../images/backgrounds/fleuron.8e44d764.svg");
  block-size: 100%;
  color: var(--color);
  font-family: var(--font-family);
}

.landing {
  block-size: 100%;
  display: grid;
  grid-template-areas: "." "main";
  grid-template-columns: minmax(0, 1024px);
  grid-template-rows: 1fr 2fr;
  justify-content: center;
  padding: 1.5rem;
}
.landing__main {
  align-content: start;
  display: grid;
  gap: 1.5rem;
  grid-area: main;
}

a {
  transition: 0.2s;
}
a:link {
  color: var(--link-color);
}
a:visited {
  color: var(--link-color-visited);
}
a:hover {
  color: var(--link-color-hover);
}
a:focus {
  color: var(--link-color-focus);
}
a:active {
  color: var(--link-color-active);
  transition: unset;
}

.liquor-beats-winter-logo {
  fill: var(--color-gray-light);
  vertical-align: middle;
}

.navigation {
  border-block: 1px solid var(--border-color);
}
.navigation ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  line-height: 1;
  list-style: none;
  padding: 0.375rem 0;
}
.navigation li {
  margin: 0.375rem 0.75rem;
}
.navigation a {
  font-weight: bold;
  letter-spacing: 0.125rem;
  text-decoration: none;
  text-transform: uppercase;
}
.navigation a.active {
  color: var(--color);
  text-decoration: underline;
  text-decoration-thickness: 0.125em;
  text-underline-offset: 0.25em;
}

.release-container {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fill, minmax(15em, 1fr));
}

.release {
  background-color: var(--block-background-color);
}

.release__info {
  padding: 1.5rem;
}

.release__title {
  font-size: 1.125em;
  line-height: 1.3333333333;
}

.release__date {
  color: var(--color-muted);
  display: block;
}

.release__links {
  list-style: none;
  margin-block-start: 1.5rem;
}
.release__links a:link {
  color: var(--color);
}
.release__links a:visited {
  color: var(--color);
}
.release__links a:hover {
  color: var(--color);
}
.release__links a:focus {
  color: var(--color);
}
.release__links a:active {
  color: var(--color);
}
.release__links [class^=icon] {
  block-size: 0.9375rem;
  fill: currentColor;
  inline-size: 0.9375rem;
  vertical-align: middle;
  margin-inline-end: 0.75rem;
}

:root {
  font-size: clamp(16px, 4vw, 24px);
}

.show-table {
  background-color: var(--block-background-color);
  table-layout: fixed;
}
.show-table tbody tr {
  border-block-start: 1px solid var(--border-color);
}
.show-table td, .show-table th {
  padding: 0.75rem 1.125rem;
}
@media (max-width: 1024px) {
  .show-table {
    display: block;
  }
  .show-table thead,
  .show-table tbody,
  .show-table tfoot,
  .show-table tr,
  .show-table th,
  .show-table td,
  .show-table caption {
    display: block;
  }
  .show-table th, .show-table td {
    min-block-size: var(--unit);
    padding: 0;
  }
  .show-table tr {
    padding: 0.75rem 1.125rem;
  }
  .show-table td[data-key] {
    padding-inline-start: 4.125rem;
    position: relative;
  }
  .show-table td[data-key]::before {
    content: attr(data-key);
    inline-size: 4.125rem;
    inset-inline-start: 0;
    position: absolute;
  }
  .show-table ::before {
    color: var(--color-muted);
    font-variant: small-caps;
    letter-spacing: 0.0625em;
    text-transform: lowercase;
  }
}

.show-table__temporal-heading {
  letter-spacing: 0.0625em;
  text-transform: uppercase;
}

.show-table__key-heading-row {
  block-size: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  inline-size: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
}

.show-table__key-heading {
  color: var(--color-muted);
  font-variant: small-caps;
  font-weight: normal;
  letter-spacing: 0.0625em;
  text-transform: lowercase;
}

.show-table__city {
  white-space: nowrap;
}

.show-table__performer-list {
  list-style: none;
}
.show-table__performer-list li {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.show-table__no-shows {
  text-align: center;
}

.social-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  margin: -1.5rem -1.5rem 0;
}
.social-links li {
  margin: 1.5rem 1.5rem 0;
}
.social-links a:link {
  color: var(--color-muted);
}
.social-links a:visited {
  color: var(--color-muted);
}
.social-links a:hover {
  color: var(--color);
}
.social-links a:focus {
  color: var(--color);
}
.social-links a:active {
  color: var(--color);
}
.social-links [class^=icon] {
  block-size: 1.125rem;
  fill: currentColor;
  inline-size: 1.125rem;
  display: block;
}