:root {
--color-black:			hsl(  0,   0%,   0%);
--color-gray-dark:		hsl(  0,   0%,  20%);
--color-gray-medium:	hsl(  0,   0%,  50%);
--color-gray-light:		hsl(  0,   0%,  90%);
--color-white:			hsl(  0,   0%, 100%);

--color-red:			rgb(218, 83, 92);

--background-color:		var(--color-gray-dark);
--color:				var(--color-gray-light);

--accent-color:			var(--color-red);
--border-color:			var(--color-gray-dark);
--color-muted:			var(--color-gray-medium);

--block-background-color:	var(--color-black);

--link-color:			var(--color-red);
--link-color-visited:	var(--link-color);
--link-color-hover:		var(--link-color);
--link-color-focus:		var(--link-color-hover);
--link-color-active:	var(--color);

--line-height: 1.25;

}