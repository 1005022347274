.landing {
	block-size: 100%;
	display: grid;
	grid-template-areas:
		"."
		"main"
	;
	grid-template-columns: minmax(0, $breakpoint-medium);
	grid-template-rows: 1fr 2fr;
	justify-content: center;
	padding: unit();

	&__main {
		align-content: start;
		display: grid;
		gap: unit();
		grid-area: main;
	}

}