.social-links {
	display: flex;
	flex-wrap: wrap;
	// gap: unit(2); // https://caniuse.com/#feat=flexbox-gap
	justify-content: center;
	list-style: none;

	margin: unit(-1) unit(-1) 0;

	li {
		margin: unit() unit() 0;
	}

	a {
		@include link-colors(var(--color-muted), var(--color-muted), var(--color));
	}

	[class^="icon"] {
		@include icon($size: unit(0.75));
		display: block;
	}

}