table {
	border-collapse: collapse;
	inline-size: 100%;
}

th {
	text-align: start;
	vertical-align: top;
}

td {
	vertical-align: top;
}

caption {
	caption-side: bottom;
	text-align: start;
}